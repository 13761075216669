import "./App.css";
import React, { useState } from "react";
import Logo from "./logo.png";
import Cloud from "./cloud.png";
import BlueCloud from "./blue-cloud.png";
import Snow from "./Snow";
import Invitation from "./Invitation";
import InformationCarousel from "./InformationCarousel";
import WeddingBot from "./WeddingBot";

function App() {
  return (
    <div className="App">
      <Snow />
      <img src={BlueCloud} className="blue-cloud-left" />
      <img src={Cloud} className="white-cloud-right" />
      <img src={BlueCloud} className="blue-cloud-right" />
      <img src={Cloud} className="white-cloud-left" />

      <header className="App-header">
        <img src={Logo} className="App-logo" />
      </header>

      <div>
        <Invitation />
      </div>

      <div>
        <InformationCarousel />
      </div>

      <div>
        <WeddingBot />
      </div>
    </div>
  );
}

export default App;
