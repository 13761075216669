import "./Invitation.css";
import React, { useState } from "react";

function Invitation() {
  return (
    <div className="Invitation">
      <div>
        You are invited be a part of our journey. Go solo yolo or bring somebody
        close to your heart. We would love it if you could join us.
      </div>

      <div>
        Together, let's travel to the land of tiny chairs, to share an intimate
        wedding celebration full of love, karaoke and (hopefully) sunshine.
      </div>

      {/*
      <h1>ITINERARY</h1>

      <br></br>

      <h3>Mon 30/12</h3>
      <div>Lorem ipsum cats. Lorem ipsum cats.</div>

      <br></br>

      <h3>Tue 31/12</h3>
      <div>Lorem ipsum cats. Lorem ipsum cats.</div>
       */}
    </div>
  );
}

export default Invitation;
