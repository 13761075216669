class ActionProvider {
  constructor(createChatBotMessage, setStateFunc) {
    this.createChatBotMessage = createChatBotMessage;
    this.setState = setStateFunc;
  }

  // Greet
  greet() {
    const greetingMessage = this.createChatBotMessage(
      `Hello! I am the BröllopsBot. 
      I can answer some general questions. 
      No specifics yet.`
    );
    this.updateChatbotState(greetingMessage);
  }

  // Dresscode
  handleDresscodeList = () => {
    const message = this.createChatBotMessage(
      `The dresscode for the wedding is simply White. 
      YES. WEAR. ONLY. WHITE.
      Regardless if you choose long or short items, 
      rough or fine fabrics, crispy cold whites or warm creamy tones - 
      you will wear all-white for the night!

      You are also welcome to tailor traditional
      Vietnamese attire for the wedding.
      As long as you won't wear it to some silly Halloween party in the future.`,
      {
        widget: "dresscodeLinks",
      }
    );
    this.updateChatbotState(message);
  };

  // TeaCeremony
  handleTeaCeremonyList = () => {
    const message = this.createChatBotMessage(
      `A tea ceremony is an opportunity to thank and honour 
      the people who raised you to be who you are, 
      as you move on to a new chapter of life. 
      It's usually held in the morning of the wedding day.`,
      {
        widget: "teaCeremonyLinks",
      }
    );

    this.updateChatbotState(message);
  };

  // Destinations
  handleDestinationList = () => {
    const message = this.createChatBotMessage(
      `Ho Chi Minh City (or Saigon) will be our base for exploring Vietnam. 
      A city of 9 million people and a country of 97 million.
      Vietnam is very safe and going solo is perfectly fine. 
      Pickpockets and scams are common but violence is rare.`,
      {
        widget: "destinationLinks",
      }
    );

    this.updateChatbotState(message);
  };

  // No gifts
  handleNoGiftsList = () => {
    const message = this.createChatBotMessage(
      `Time is the greatest gift - Sami and Isaac will be honoured to share their day with you! 
      How about the gift of singing a song at their wedding?
      Sami and Isaac are minimalists and travel light so try not to add stuff to their luggage.
      Unless it's a Red Pocket with money. No shame, honey!`
    );

    this.updateChatbotState(message);
  };

  // No kids
  handleNoKidsList = () => {
    const message = this.createChatBotMessage(
      `Kids are welcome to join our Vietnam Vacation. 
      For the wedding celebration, kids will stay away,
      let's take the night off and enjoy the party 100%!`
    );

    this.updateChatbotState(message);
  };

  // No speeches
  handleNoSpeechesList = () => {
    const message = this.createChatBotMessage(
      `All has been said. No need for speeches. Unless you will sing it.
      Sami and Isaac will simply give thanks to their parents during the tea ceremony.
      That's it. All you need is love. And karaoke.`
    );

    this.updateChatbotState(message);
  };

  // Karaoke
  handleKaraokeList = () => {
    const message = this.createChatBotMessage(
      `The go-to musical entertainment for the Vietnamese is karaoke.
      Karaoke during the wedding is mandatory so please prepare a song. 
      Just kidding! But feel free to go for Karaoke Light, 
      or why not sing together? 
      Sami and Isaac are already rehearsing weekly.`
    );

    this.updateChatbotState(message);
  };

  // Packing
  handlePackingList = () => {
    const message = this.createChatBotMessage(
      `Pack the usual things: passport, money, medication, adapter, sunscreen, flipflops.
      Vietnam is very cheap in case you forget something. 
      Earplugs, sleeping mask, wired headphones are useful for the flight. 
      Always keep things you can't live without in your carry-on, in case your baggage gets lost.`
    );

    this.updateChatbotState(message);
  };

  // Wedding Customs
  handleWeddingCustomsList = () => {
    const message = this.createChatBotMessage(
      `Vietnamese weddings consist of a tea and candle lighting ceremony.
      Food is eaten family style with 6-8 dishes including roasted suckling pig. 
      Red and gold are considered lucky colours while black is not and should be avoided.
      But who cares? Sami certainly doesn't. 
      Still, here are some pictures to give you an idea.`,
      {
        widget: "weddingCustomsLinks",
      }
    );

    this.updateChatbotState(message);
  };

  // Hotels
  handleHotelsList = () => {
    const message = this.createChatBotMessage(
      `We will spend some time in Ho Chi Minh City.
      Maybe visit the Cu Chi Tunnels?
      If you say Yes, we will plan the trip together,
      and try to stay within everybody's budget.`
    );

    this.updateChatbotState(message);
  };

  // Budget
  handleBudgetList = () => {
    const message = this.createChatBotMessage(
      `The currency is Vietnamese Dong (VND) 
      but it is better to bring american dollars (USD). 
      Get large 100 dollar bills as this gives you a better exchange rate.
      Cash is king but bring at least one credit card. 
      Do not give money to begging children.
      Below are some resources regarding budgeting your trip to Vietnam.
      `,
      {
        widget: "budgetLinks",
      }
    );

    this.updateChatbotState(message);
  };

  // Foods
  handleFoodList = () => {
    const message = this.createChatBotMessage(
      `The Vietnamese loves herbs! Popular dishes include noodle soups, 
      banh mi (a French-influenced baguette filled with various ingredients), 
      and goi cuon - fresh spring rolls with vermicelli noodles and lots of herbs!`,
      {
        widget: "foodLinks",
      }
    );

    this.updateChatbotState(message);
  };

  // Vaccination
  handleVaccinationList = () => {
    const message = this.createChatBotMessage(
      `The recommendation for Vietnam is vaccination against Hepatitis A 
      and up-to-date vaccinations against "the basics": Tetanus (stelkramp), Diphtheria (difteri) and Polio.
      If you are staying for longer periods, do consider: Hepatitis B, Japanese encephalitis, cholera, and typhoid.`,
      {
        widget: "vaccinationLinks",
      }
    );

    this.updateChatbotState(message);
  };

  // Weather
  handleWeatherList = () => {
    const message = this.createChatBotMessage(
      `Winter (December to February) in Vietnam is dry season,
      but the weather can differ a lot between the North, Central and South.
      In the South regions, where the wedding will be,
      it's mostly dry with a mix of ocassional light rain and sunshine.
      Temperatures range from 22 to 32°C.`,
      {
        widget: "weatherLinks",
      }
    );

    this.updateChatbotState(message);
  };

  handleNoUnderstandingList = () => {
    const message = this.createChatBotMessage(
      "I don't understand what you mean. In the end, I am simply a BröllopsBot. Sorry not sorry."
    );
    this.updateChatbotState(message);
  };
  updateChatbotState(message) {
    this.setState((prevState) => ({
      ...prevState,
      messages: [...prevState.messages, message],
    }));
  }
}

export default ActionProvider;
