import "./WeddingRules.css";

function WeddingRules() {
  return (
    <div className="WeddingRules">
      <h1>wedding rules</h1>

      <p>wear white</p>

      <p>sing karaoke</p>

      <p>no kids</p>
    </div>
  );
}

export default WeddingRules;
