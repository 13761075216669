import React from "react";
import { createChatBotMessage } from "react-chatbot-kit";

import Options from "../components/Options/Options";
import ImageLinkList from "../components/ImageLinkList/ImageLinkList";

const config = {
  botName: "BröllopsBot",
  initialMessages: [
    createChatBotMessage(
      `I am the BröllopsBot. 
      Select a topic below or write your own question.
      Want to ask about vaccinations, food, weather, or something else?
      I don't have all the answers but try me.`,
      {
        widget: "options",
        with: false,
        delay: 1000,
      }
    ),
  ],
  inputPlaceholder: "Ask a question.",
  customStyles: {
    botMessageBox: {
      backgroundColor: "#c1d5e1",
    },
    chatButton: {
      backgroundColor: "#c1d5e1",
    },
  },
  widgets: [
    {
      widgetName: "options",
      widgetFunc: (props) => <Options {...props} />,
    },
    {
      widgetName: "weddingCustomsLinks",
      widgetFunc: (props) => <ImageLinkList {...props} />,
      props: {
        options: [
          {
            imageUrl:
              "https://statics.vinwonders.com/Vietnamese-wedding-tea-ceremony-04_1701362610.jpg",
          },
          {
            imageUrl:
              "https://images.squarespace-cdn.com/content/v1/535f2ae4e4b0e6e78cf3b5f9/1582935885873-EPW8MC6T3W7B62TOWS88/Vietnamese-weddings-columbus-ohio-enabeld-photography-enabeld-enabled-weddings-best-photographer-candle-ceremony+%287%29.JPG?format=500w",
          },
          {
            imageUrl:
              "https://media-api.xogrp.com/images/de816995-3504-4ec1-8745-c841b8c502b7~rs_768.h",
          },
          {
            imageUrl:
              "https://www.cargo-partner.com/fileadmin/_processed_/5/e/csm_shutterstock_767508352_598db3c0a9.webp",
          },
          {
            imageUrl:
              "https://imageproxy.hieunguyen.dev/api/images/dfgbpib38/image/upload/w_1200,f_auto/wp-content/uploads/2021/09/AnTrung_Wedding_RUOCDAU_433-ADS_1513.jpg",
          },
        ],
      },
    },
    {
      widgetName: "dresscodeLinks",
      widgetFunc: (props) => <ImageLinkList {...props} />,
      props: {
        options: [
          {
            imageUrl:
              "https://balieventhire.com/cdn/shop/files/Clare-Nathan-Main_1800x.jpg?v=1657459151",
          },
          {
            imageUrl:
              "https://img.resized.co/socialandpersonalweddings/eyJkYXRhIjoie1widXJsXCI6XCJodHRwczpcXFwvXFxcL21lZGlhLnNvY2lhbGFuZHBlcnNvbmFsd2VkZGluZ3MuaWVcXFwvdXBsb2Fkc1xcXC8yMDIxXFxcLzEyXFxcLzE1MDkwMDIxXFxcL0ZlYXR1cmVkLUltYWdlLTI4LTEwMjR4NjE2LnBuZ1wiLFwid2lkdGhcIjoxMTQwLFwiaGVpZ2h0XCI6Njg2LFwiZGVmYXVsdFwiOlwiaHR0cHM6XFxcL1xcXC93d3cuc29jaWFsYW5kcGVyc29uYWx3ZWRkaW5ncy5pZVxcXC9pXFxcL25vLWltYWdlLnBuZz92PTFcIn0iLCJoYXNoIjoiNjY2ZDg0NjgyODFjOWFlZjQ4ZTQ2ZmVmMmZiOGFlMTI3ZjY5NTY5ZSJ9/featured-image-28-1024x616.png",
          },
        ],
      },
    },
    {
      widgetName: "destinationLinks",
      widgetFunc: (props) => <ImageLinkList {...props} />,
      props: {
        options: [
          {
            text: "Ho Chi Minh City",
            imageUrl:
              "https://www.jetstar.com/_/media/destinations/images/vietnam/ho-chi-minh-city/gallery/shutterstock-227057737.jpg?rev=5d4992e6fc4c4dc988f4ab879fafc05b&w=1290&rc=1&cw=1290&ch=400&cx=203&cy=0&hash=ECC46D8095D46639321DECCDDDCAA78E8B417C68",
            url: "https://vietnam.travel/things-to-do/7-must-see-attractions-hcmc",
          },
          {
            text: "Vung Tau",
            imageUrl:
              "https://www.vietnamonline.com/media/cache/a2/9b/a29b2c7f6876438b3d4e915ce4d73dbe.jpg",
            url: "https://vietnam.travel/things-to-do/vung-tau-sightseeing-wonderland",
          },
          {
            text: "Mui Ne",
            imageUrl:
              "https://whileyoustayhome.com/wp-content/uploads/2019/10/Mui-Ne-sand-dunes-and-fairy-streams-while-you-stay-home-3.jpg",
            url: "https://vietnam.travel/things-to-do/wind-sand-and-sea-mui-nes-must-do-list",
          },
          {
            text: "Con Dao Islands",
            imageUrl:
              "https://sohagroup.vn/wp-content/uploads/2023/07/Bai-Dam-Trau-f.jpg",
            url: "https://vietnam.travel/places-to-go/southern-vietnam/con-dao",
          },
          {
            text: "Nha Trang",
            imageUrl:
              "https://img.traveltriangle.com/blog/wp-content/uploads/2019/01/nha-trang-tour-guide-cover.jpg",
            url: "https://vietnam.travel/places-to-go/central-vietnam/nha-trang",
          },
          {
            text: "Phu Quoc Island",
            imageUrl:
              "https://res.klook.com/image/upload/c_crop,w_2712,h_1020,x_1,y_0/q_85,w_1808,h_680/w_80,x_15,y_15,g_south_west,l_Klook_water_br_trans_yhcmh3/destination/mcfj4dhaenuyz23py93h.webp",
            url: "https://vietnam.travel/places-to-go/southern-vietnam/phu-quoc",
          },
        ],
      },
    },
    {
      widgetName: "budgetLinks",
      widgetFunc: (props) => <ImageLinkList {...props} />,
      props: {
        options: [
          {
            text: "Costs in Vietnam",
            url: "https://listsbylukiih.com/vietnam-trip-cost-breakdown/",
          },
          {
            text: "Vietnam on a budget",
            url: "https://www.lonelyplanet.com/articles/vietnam-on-a-budget",
          },
          {
            text: "Budget your trip",
            url: "https://budgetyourtrip.com/budgetreportadv.php?geonameid=&countrysearch=&country_code=VN&categoryid=0&budgettype=1&triptype=0&startdate=&enddate=&travelerno=0",
          },
        ],
      },
    },
    {
      widgetName: "weatherLinks",
      widgetFunc: (props) => <ImageLinkList {...props} />,
      props: {
        options: [
          {
            text: "Weather in Southern Vietnam",
            imageUrl:
              "https://serenaslenses.net/wp-content/uploads/2022/08/Screen-Shot-2022-08-31-at-9.34.04-PM.png",
            url: "https://www.bestpricetravel.com/travel-guide/south-vietnam-weather-2503.html#:~:text=There%20are%20two%20distinct%20seasons,November%20to%20April%20next%20year.",
          },
          {
            text: "Best time to visit Vietnam",
            imageUrl:
              "https://jackytravel.com/wp-content/uploads/2019/07/Best-time-to-visit-Vietnam-2.jpg",
            url: "https://www.selectiveasia.com/vietnam-holidays/weather",
          },
        ],
      },
    },
    {
      widgetName: "foodLinks",
      widgetFunc: (props) => <ImageLinkList {...props} />,
      props: {
        options: [
          {
            imageUrl:
              "https://drivemehungry.com/wp-content/uploads/2020/06/vegetarian-summer-rolls-3.jpg",
          },
          {
            imageUrl:
              "https://www.connoisseurusveg.com/wp-content/uploads/2022/10/vegan-pho-22-r-2.jpg",
          },
        ],
      },
    },
  ],
};

export default config;
