import React from "react";

import "./Options.css";

const Options = (props) => {
  const options = [
    { text: "About Vietnam", handler: props.actionProvider.handleDestinationList, id: 3 },
    { text: "Cash or Card?", handler: props.actionProvider.handleBudgetList, id: 10 },
    { text: "Vietnamese Weddings", handler: props.actionProvider.handleWeddingCustomsList, id: 7 },
    { text: "Wedding Dresscode?", handler: props.actionProvider.handleDresscodeList, id: 1 },
    { text: "Winter Weather", handler: props.actionProvider.handleWeatherList, id: 14 },
    /* 
        { text: "No gifts? No gifts!", handler: props.actionProvider.handleNoGiftsLis
    */
      ];

  const optionsMarkup = options.map((option) => (
    <button
      className="option-button"
      key={option.id}
      onClick={option.handler}
    >
      {option.text}
    </button>
  ));

  return <div className="options-container">{optionsMarkup}</div>;
};

export default Options;