import React from "react";
import Calendar from "react-calendar";
import "./WeddingCalendar.css"; // Place before react default styles for priority
import "react-calendar/dist/Calendar.css"; // Import the default styles
import { useState } from "react";

function WeddingCalendar() {
  // Set an array of selected dates for the calendar
  const selectedDateJan = new Date(2025, 0, 3);
  const selectedDateDec = new Date(2024, 11, 30);

  const [date, setDate] = useState([
    new Date(2024, 11, 30),
    new Date(2025, 0, 5),
  ]);

  // Function to determine if a date is within the range
  const isDateInRange = (date) => {
    const startDate = new Date(2024, 11, 30); // 30th of December
    const endDate = new Date(2025, 0, 5); // 5th of January
    return date >= startDate && date <= endDate;
  };

  // Function to provide custom content for date tiles
  const tileContent = ({ date, view }) => {
    if (
      date.toDateString() === selectedDateJan.toDateString() ||
      date.toDateString() === selectedDateDec.toDateString()
    ) {
      return (
        <div
          style={{
            backgroundColor: "white",
            borderRadius: "0%",
            padding: "2px",
            marginTop: "1px",
          }}
        ></div>
      );
    }
    return null;
  };

  return (
    <div className="WeddingCalendar">
      {/* Render the Calendar component with the fixed date and tileContent prop */}
      <Calendar
        selectRange={true}
        minDate={new Date(2024, 11, 22)} // will not allow date earlier
        maxDate={new Date(2025, 0, 12)} // will not allow date later
        defaultValue={date}
        onChange={setDate}
        defaultActiveStartDate={new Date(2025, 0, 2)}
        tileContent={tileContent}
      />
    </div>
  );
}

export default WeddingCalendar;
