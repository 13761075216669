class MessageParser {
  constructor(actionProvider) {
    this.actionProvider = actionProvider;
  }

  parse(message) {
    const lowerCaseMessage = message.toLowerCase();

    if (
      lowerCaseMessage.includes("suit") ||
      lowerCaseMessage.includes("tuxedo") ||
      lowerCaseMessage.includes("black tie") ||
      lowerCaseMessage.includes("white") ||
      lowerCaseMessage.includes("wear") ||
      lowerCaseMessage.includes("clothes") ||
      lowerCaseMessage.includes("clothing") ||
      lowerCaseMessage.includes("attire") ||
      lowerCaseMessage.includes("dresscode") ||
      lowerCaseMessage.includes("dress")
    ) {
      this.actionProvider.handleDresscodeList();
    } else if (
      lowerCaseMessage.includes("ceremony") ||
      lowerCaseMessage.includes("tea")
    ) {
      this.actionProvider.handleTeaCeremonyList();
    } else if (
      lowerCaseMessage.includes("gift") ||
      lowerCaseMessage.includes("present")
    ) {
      this.actionProvider.handleNoGiftsList();
    } else if (
      lowerCaseMessage.includes("child") ||
      lowerCaseMessage.includes("baby") ||
      lowerCaseMessage.includes("kid")
    ) {
      this.actionProvider.handleNoKidsList();
    } else if (
      lowerCaseMessage.includes("speech") ||
      lowerCaseMessage.includes("vows")
    ) {
      this.actionProvider.handleNoSpeechesList();
    } else if (
      lowerCaseMessage.includes("music") ||
      lowerCaseMessage.includes("karaoke") ||
      lowerCaseMessage.includes("sing")
    ) {
      this.actionProvider.handleKaraokeList();
    } else if (
      lowerCaseMessage.includes("luggage") ||
      lowerCaseMessage.includes("carry on") ||
      lowerCaseMessage.includes("backpack") ||
      lowerCaseMessage.includes("trolley") ||
      lowerCaseMessage.includes("suitcase") ||
      lowerCaseMessage.includes("bring") ||
      lowerCaseMessage.includes("pack")
    ) {
      this.actionProvider.handlePackingList();
    } else if (
      lowerCaseMessage.includes("host") ||
      lowerCaseMessage.includes("house") ||
      lowerCaseMessage.includes("housing") ||
      lowerCaseMessage.includes("accommodation") ||
      lowerCaseMessage.includes("sleep") ||
      lowerCaseMessage.includes("hotel") ||
      lowerCaseMessage.includes("stay") ||
      lowerCaseMessage.includes("live")
    ) {
      this.actionProvider.handleHotelsList();
    } else if (
      lowerCaseMessage.includes("atm") ||
      lowerCaseMessage.includes("card") ||
      lowerCaseMessage.includes("money") ||
      lowerCaseMessage.includes("cost") ||
      lowerCaseMessage.includes("budget") ||
      lowerCaseMessage.includes("currency") ||
      lowerCaseMessage.includes("cash")
    ) {
      this.actionProvider.handleBudgetList();
    } else if (
      lowerCaseMessage.includes("rain") ||
      lowerCaseMessage.includes("cold") ||
      lowerCaseMessage.includes("hot") ||
      lowerCaseMessage.includes("autumn") ||
      lowerCaseMessage.includes("spring") ||
      lowerCaseMessage.includes("summer") ||
      lowerCaseMessage.includes("weather") ||
      lowerCaseMessage.includes("sunshine") ||
      lowerCaseMessage.includes("sun") ||
      lowerCaseMessage.includes("wet") ||
      lowerCaseMessage.includes("season") ||
      lowerCaseMessage.includes("climate") ||
      lowerCaseMessage.includes("monsoon") ||
      lowerCaseMessage.includes("tsunami") ||
      lowerCaseMessage.includes("winter")
    ) {
      this.actionProvider.handleWeatherList();
    } else if (
      lowerCaseMessage.includes("breakfast") ||
      lowerCaseMessage.includes("lunch") ||
      lowerCaseMessage.includes("dinner") ||
      lowerCaseMessage.includes("food") ||
      lowerCaseMessage.includes("eat") ||
      lowerCaseMessage.includes("vegetarian") ||
      lowerCaseMessage.includes("vegan") ||
      lowerCaseMessage.includes("veggie") ||
      lowerCaseMessage.includes("cuisine")
    ) {
      this.actionProvider.handleFoodList();
    } else if (
      lowerCaseMessage.includes("violence") ||
      lowerCaseMessage.includes("danger") ||
      lowerCaseMessage.includes("safe") ||
      lowerCaseMessage.includes("place") ||
      lowerCaseMessage.includes("country") ||
      lowerCaseMessage.includes("asia") ||
      lowerCaseMessage.includes("destination") ||
      lowerCaseMessage.includes("location") ||
      lowerCaseMessage.includes("vietnam")
    ) {
      this.actionProvider.handleDestinationList();
    } else if (
      lowerCaseMessage.includes("vaccine") ||
      lowerCaseMessage.includes("vaccination")
    ) {
      this.actionProvider.handleVaccinationList();
    } else if (
      lowerCaseMessage.includes("hejsan") ||
      lowerCaseMessage.includes("tjena") ||
      lowerCaseMessage.includes("hello") ||
      lowerCaseMessage.includes("hi") ||
      lowerCaseMessage.includes("hey") ||
      lowerCaseMessage.includes("hej")
    ) {
      this.actionProvider.greet();
    } else {
      this.actionProvider.handleNoUnderstandingList();
    }
  }
}

export default MessageParser;
