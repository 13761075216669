// WeddingBot.js

import React, { useState, useEffect, useRef } from "react";
import Chatbot from "react-chatbot-kit";
import "react-chatbot-kit/build/main.css";

import ActionProvider from "./chatbot/ActionProvider";
import MessageParser from "./chatbot/MessageParser";
import config from "./chatbot/config";
import "./WeddingBot.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faComment } from "@fortawesome/free-solid-svg-icons";
import { faTimes } from "@fortawesome/free-solid-svg-icons";

function WeddingBot() {
  const [isOpen, setIsOpen] = useState(false);
  const [isShaking, setIsShaking] = useState(false);
  const chatWindowRef = useRef(null);

  const toggleChat = () => {
    setIsOpen(!isOpen);
  };

  const closeChatOnOutsideClick = (event) => {
    if (
      chatWindowRef.current &&
      !chatWindowRef.current.contains(event.target) &&
      isOpen
    ) {
      setIsOpen(false);
    }
  };

  useEffect(() => {
    const shakeInterval = setInterval(() => {
      if (!isOpen) {
        setIsShaking(true);
        setTimeout(() => {
          setIsShaking(false);
        }, 2000); // Shake for 2 seconds
      }
    }, 15000); // Trigger every 15 seconds

    document.addEventListener("mousedown", closeChatOnOutsideClick);

    return () => {
      clearInterval(shakeInterval);
      document.removeEventListener("mousedown", closeChatOnOutsideClick);
    };
  }, [isOpen]);

  return (
    <div className={`WeddingBot ${isOpen ? "open" : ""}`}>
      {!isOpen && (
        <div
          className={`chat-toggle-button ${isShaking ? "shake" : ""}`}
          onClick={toggleChat}
        >
          {isOpen ? (
            <FontAwesomeIcon icon={faTimes} style={{ color: "#c1d5e1" }} />
          ) : (
            <FontAwesomeIcon icon={faComment} style={{ color: "#c1d5e1" }} />
          )}
        </div>
      )}
      {isOpen && (
        <div className="chat-window" ref={chatWindowRef}>
          <Chatbot
            config={config}
            actionProvider={ActionProvider}
            messageParser={MessageParser}
            placeholderText="Ask a question."
          />
        </div>
      )}
    </div>
  );
}

export default WeddingBot;
