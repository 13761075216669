import React from "react";
import "./WeddingMap.css";

function WeddingMap() {
  return (
    <div className="Map">
      <div className="mapouter">
        <div className="gmap_canvas">
          <iframe
            className="gmap_iframe"
            title="Google Map"
            width="100%"
            frameBorder="0"
            scrolling="no"
            marginHeight="0"
            marginWidth="0"
            src="https://maps.google.com/maps?width=530&amp;height=445&amp;hl=en&amp;q=bui%20vien&amp;t=&amp;z=11&amp;ie=UTF8&amp;iwloc=B&amp;output=embed"
          ></iframe>
        </div>
      </div>
    </div>
  );
}

export default WeddingMap;
