import React from "react";
import "./ImageLinkList.css";

const ImageLinkList = (props) => {
  const linkMarkup = props.options.map((link) => (
    <li key={link.id} className="link-list-item">
      <a
        href={link.url}
        target="_blank"
        rel="noopener noreferrer"
        className="link-list-item-url"
      >
        {link.text}
        {link.imageUrl && (
        <img src={link.imageUrl} alt={link.text} className="link-list-item-image" />
      )}
      </a>
    </li>
  ));

  return <ul className="link-list">{linkMarkup}</ul>;
};

export default ImageLinkList;
