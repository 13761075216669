import React, { useState, useEffect } from "react";
import "./InformationCarousel.css";
import WeddingMap from "./WeddingMap.js";
import WeddingCalendar from "./WeddingCalendar";
import WeddingRules from "./WeddingRules.js";

const slides = [
  // Define your slide content here
  { id: 1, content: <WeddingCalendar /> },
  { id: 2, content: <WeddingMap /> },
  { id: 3, content: <WeddingRules /> },
];

function InformationCarousel() {
  const [currentSlide, setCurrentSlide] = useState(1);
  const [autoSlideEnabled, setAutoSlideEnabled] = useState(true);

  useEffect(() => {
    const interval = setInterval(() => {
      if (autoSlideEnabled) {
        setCurrentSlide((prevSlide) =>
          prevSlide === slides.length ? 1 : prevSlide + 1
        );
      }
    }, 8000); // Adjust the duration as needed

    return () => clearInterval(interval);
  }, [autoSlideEnabled]);

  const changeSlide = (slideNumber) => {
    setCurrentSlide(slideNumber);
    setAutoSlideEnabled(false); // Stop automatic slide change after the first cycle
  };

  return (
    <div className="carousel-container">
      <div
        className="carousel-track"
        style={{ transform: `translateX(-${(currentSlide - 1) * 100}%)` }}
      >
        {slides.map((slide) => (
          <div key={slide.id} className="carousel-slide">
            {slide.content}
          </div>
        ))}
      </div>
      <div className="carousel-dots">
        {slides.map((slide) => (
          <div
            key={slide.id}
            className={`dot ${currentSlide === slide.id ? "active" : ""}`}
            onClick={() => changeSlide(slide.id)}
          />
        ))}
      </div>
    </div>
  );
}

export default InformationCarousel;
